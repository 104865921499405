import React, { useState } from "react";
import Modal from "./Modal"; // Make sure to import your Modal component
import mainImage from "../assets/logo_full.png"; // Update the path to your image

const Hero = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility

  // Function to handle button click to open the modal
  const handleLearnMoreClick = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Function to handle selection in the modal
  const handleSelect = (role) => {
    console.log("Selected role:", role);
    setIsModalOpen(false); // Close the modal after selection
  };

  return (
    <section className="bg-black text-white h-screen flex flex-col md:flex-row">
      <div className="flex flex-col justify-center p-10 md:w-1/2">
        <h1 className="text-5xl font-bold">Barcard connects bar owners and customers through a centralized platform.
        </h1>
        <p className="mt-4 text-lg">
          We're working hard on revolutionizing the bar industry.
        </p>
        <div className="mt-8 flex space-x-4">
        <button 
            className="bg-yellow-500 text-black font-semibold py-2 px-4 rounded"
            onClick={() => window.open("https://testflight.apple.com/join/wFvJfxR3", "_blank")} // Add your iOS testing functionality here
          >
            Test Our iOS App
          </button>
          <button 
            className="bg-yellow-500 text-black font-semibold py-2 px-4 rounded"
            onClick={handleLearnMoreClick} // Open the modal on button click
          >
            Join Our Android Waitlist
          </button>
        </div>
      </div>
      <div className="w-full md:w-1/2 flex items-center justify-center mt-10 md:mt-0">
        <img 
          src={mainImage} 
          alt="Main" 
          className="object-contain w-3/4 h-auto"
        />
      </div>
      <Modal 
        isOpen={isModalOpen} 
        onClose={handleCloseModal} 
        onSelect={handleSelect}
      />
    </section>
  );
};

export default Hero;
