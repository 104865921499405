import React, { useState, useEffect } from "react";

const Footer = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [privacyPolicyContent, setPrivacyPolicyContent] = useState("");

  useEffect(() => {
    // Fetching the HTML content from the public directory
    fetch("./privacypolicy.html")
      .then((response) => response.text())
      .then((data) => setPrivacyPolicyContent(data))
      .catch((err) => console.error("Failed to load privacy policy:", err));
  }, []);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <footer className="bg-black py-6">
      <div className="container mx-auto text-center text-white">
        &copy; 2024 barcard.app. All rights reserved.
        <br />
        <button onClick={openModal} className="bg-gray-700 text-white px-4 py-2 mt-4">
          Privacy Policy
        </button>

        {/* Custom Modal */}
        {modalIsOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-black text-white p-10 rounded-lg shadow-lg max-w-2xl relative">
              <h2 className="text-2xl font-bold mb-4">Privacy Policy</h2>
              <div style={{ overflowY: "auto", maxHeight: "400px" }}>
                <div dangerouslySetInnerHTML={{ __html: privacyPolicyContent }} />
              </div>
              <button
                onClick={closeModal}
                className="bg-gray-700 text-white px-4 py-2 mt-4"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </footer>
  );
};

export default Footer;
