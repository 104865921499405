import React from "react";

const Features = () => {
  return (
    <section id="features" className="py-20 bg-yellow-500"> {/* Changed background to gold */}
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-10 text-black">Features</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
          {/* Bar Owner Features Section */}
          <div>
            <h3 className="text-xl font-bold mb-4 text-black">Bar Owners</h3>
            <div className="grid grid-cols-1 gap-6">
              <div className="p-6 bg-black shadow-md rounded-lg">
                <h4 className="font-semibold text-xl mb-2 text-white">Rewards Management</h4>
                <p className="text-white">Easily create and manage rewards for your regular customers.</p>
              </div>
              <div className="p-6 bg-black shadow-md rounded-lg">
                <h4 className="font-semibold text-xl mb-2 text-white">Event Operations</h4>
                <p className="text-white">Host events, manage guestlists, and oversee ticketing.</p>
              </div>
              <div className="p-6 bg-black shadow-md rounded-lg">
                <h4 className="font-semibold text-xl mb-2 text-white">Customer Analytics</h4>
                <p className="text-white">Gain insights into customer preferences and spending through our integrations with leading point of sale systems.</p>
              </div>
            </div>
          </div>

          {/* Bar Customer Features Section */}
          <div>
            <h3 className="text-xl font-bold mb-4 text-black">Bar Customers</h3>
            <div className="grid grid-cols-1 gap-6">
              <div className="p-6 bg-black shadow-md rounded-lg">
                <h4 className="font-semibold text-xl mb-2 text-white">Earn Free Drinks</h4>
                <p className="text-white">Get rewarded for spending at local bars.</p>
              </div>
              <div className="p-6 bg-black shadow-md rounded-lg">
                <h4 className="font-semibold text-xl mb-2 text-white">Track Spending</h4>
                <p className="text-white">Effortlessly track your purchases to get a clear view of your spending.</p>
              </div>
              <div className="p-6 bg-black shadow-md rounded-lg">
                <h4 className="font-semibold text-xl mb-2 text-white">Discover Events</h4>
                <p className="text-white">Find and RSVP for nearby events. </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
