import React from "react";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Features from "./components/Features";
import About from "./components/About";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="flex flex-col min-h-screen"> {/* Ensure full height for the app */}
      <Navbar />
      <Hero /> {/* Keep Hero directly below the Navbar */}
      <div className="flex-grow"> {/* This allows the features and about section to grow and fill the space */}
        <Features />
        <About />
      </div>
      <Footer /> {/* Footer will stay at the bottom */}
    </div>
  );
}

export default App;
