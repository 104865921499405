import React from "react";

const About = () => {
  return (
    <section id="about" className="py-20 bg-black"> 
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-6 text-white">About Us</h2> 
        <p className="text-white max-w-3xl mx-auto">
          We're a small team passionate about revolutionizing the relationship between bar owners and their customers. Our app is currently in beta testing. 
        </p>
      </div>
    </section>
  );
};

export default About;
