import React from "react";
import logo from "../assets/logo_barcard.png"; // Adjust the path based on your actual file structure

const Navbar = () => {
  return (
    <nav className="bg-black p-4">
      <div className="container mx-auto flex justify-between items-center">
        <a href="/" className="flex items-center">
          <img src={logo} alt="Startup Logo" className="h-10" /> {/* Adjust height as needed */}
        </a>
        <div className="space-x-6"> {/* Increased spacing between links */}
          <a href="#features" className="text-white hover:text-yellow-500 text-lg">Features</a> {/* Increased font size */}
          <a href="#about" className="text-white hover:text-yellow-500 text-lg">About</a> {/* Increased font size */}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
