import React, { useState } from "react";
import emailjs from "emailjs-com";

const Modal = ({ isOpen, onClose }) => {
  const [selectedRole, setSelectedRole] = useState(null); // State to track selected role
  const [submitted, setSubmitted] = useState(false); // State to track submission status
  const [formData, setFormData] = useState({ name: "", email: "", phone: "" }); // State for form data

  if (!isOpen) return null; // Don't render the modal if it's not open

  const handleRoleSelect = (role) => {
    setSelectedRole(role); // Set the selected role when a button is clicked
  };

  const handleBack = () => {
    setSelectedRole(null); // Go back to the role selection view
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value })); // Update form data state
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission

    const templateParams = {
      subject: selectedRole === "barOwner" ? "Bar Owner" : "Customer", // Dynamic subject
      name: formData.name, // User's name
      email: formData.email, // User's email
      phone: formData.phone, // User's phone
      date: new Date().toLocaleString(), // Current date and time
    };

    // Send email using EmailJS
    emailjs
      .send("service_899a02e", "template_1bads2m", templateParams, "OLxIKUQ7d86UjECZF")
      .then(() => {
        setSubmitted(true); // Update submission status to true
      })
      .catch((error) => {
        console.error("Failed to send email:", error);
      });
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white p-10 rounded-lg shadow-lg text-center max-w-2xl"> {/* Consistent box size */}
        {submitted ? (
          // Success message
          <div>
            <h2 className="text-2xl font-bold mb-6 text-black">Successfully signed up for the waitlist!</h2>
            <button
              className="mt-6 text-gray-500 hover:text-gray-800 text-lg"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        ) : selectedRole ? (
          // Form view for Name, Email, Phone Number
          <div>
            <h2 className="text-2xl font-bold mb-6 text-black">Enter Your Details</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <input
                  type="text"
                  name="name"
                  className="border rounded w-full px-3 py-2 text-black placeholder-gray-500" // Set text color to black and added placeholder color
                  placeholder="Name" // Placeholder for Name
                  value={formData.name} // Controlled input
                  onChange={handleInputChange} // Handle input change
                  required
                />
              </div>
              <div className="mb-4">
                <input
                  type="email"
                  name="email"
                  className="border rounded w-full px-3 py-2 text-black placeholder-gray-500" // Set text color to black and added placeholder color
                  placeholder="Email" // Placeholder for Email
                  value={formData.email} // Controlled input
                  onChange={handleInputChange} // Handle input change
                  required
                />
              </div>
              <div className="mb-4">
                <input
                  type="tel"
                  name="phone"
                  className="border rounded w-full px-3 py-2 text-black placeholder-gray-500" // Set text color to black and added placeholder color
                  placeholder="Phone Number" // Placeholder for Phone Number
                  value={formData.phone} // Controlled input
                  onChange={handleInputChange} // Handle input change
                  required
                />
              </div>
              <button
                type="submit"
                className="bg-yellow-500 text-black text-lg px-6 py-3 rounded"
              >
                Submit
              </button>
            </form>
            <button
              className="mt-6 text-gray-500 hover:text-gray-800 text-lg"
              onClick={handleBack}
            >
              Back
            </button>
          </div>
        ) : (
          // Role selection view
          <div>
            <h2 className="text-2xl font-bold mb-6 text-black">What best describes you?</h2>
            <div className="space-x-6">
              <button
                className="bg-yellow-500 text-black text-lg px-6 py-3 rounded"
                onClick={() => handleRoleSelect("barOwner")}
              >
                Bar Owner
              </button>
              <button
                className="bg-yellow-500 text-black text-lg px-6 py-3 rounded"
                onClick={() => handleRoleSelect("customer")}
              >
                Customer
              </button>
            </div>
            <button
              className="mt-6 text-gray-500 hover:text-gray-800 text-lg"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
